.tabs{
    height: getSpacingValues(5);
    display: flex;
    flex-direction: row;

    .tabs__title {
        line-height: getSpacingValues(5);
        font-weight: getFontWeights(bold);
        color: getNeutralColors(blackSecondaryText);
        cursor: pointer;

        &.tabs__selected {
            color: getTextColors(primaryBaseText);
            position: relative;

            &::after {
                position: absolute;
                content: '';
                width: 100%;
                height: getSpacingValue(1);
                left: getSpacingValue(0);
                border-radius: getSpacingValue(1) getSpacingValue(1) 0 0;
                background-color: getPrimaryColors(primaryBase);
            }
        }
    }
}